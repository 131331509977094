<template lang="pug">
  div
    .are-classroom-tooltip
      p.txt-classroom-phone
        font-awesome-icon.mr-1(icon='phone')
        | {{ $t('classroom.calling_help') }}
        a.lnk-classroom-phone(:href="'tel:' + company_phone")
          | {{company_phone}}
</template>
<script>
import companyConstant from '@/constants/company';
export default {
  name: 'PhoneHelp',
  data() {
    return {
      company_phone: companyConstant.INFO.CONTACT_PHONE,
    };
  },
};
</script>
