<template>
  <div class="reg-class-total">
    <div class="are-class-total">
      {{ $t('class.week_finished_class') }} &nbsp;: {{ ClassRecordsWeekly.week_class_count }} {{ $t('class.class') }}
    </div>
    <div class="are-class-total">
      {{ $t('class.behind_class') }} &nbsp;: {{ ClassRecordsWeekly.week_balance_class }} {{ $t('class.class') }}
    </div>
  </div>
</template>

<script type="text/javascript">
import LioshutanApi from '@/api';
export default {
  name: 'VipWeekClassCount',

  components: {},

  data() {
    return {
      ClassRecordsWeekly: {},
    };
  },

  created(){
    this.getClassRecordsWeekly();
  },

  methods: {
    async getClassRecordsWeekly(){
      const res = await LioshutanApi.vip.getVipClassRecordsWeekly();
      this.ClassRecordsWeekly = res.data;
    },
  },
};
</script>
