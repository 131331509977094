<template lang="pug">
  //-統一跟一般課程、隨選隨上、百家書苑API回傳格式
  div
    server-time-clock
    phone-help
    br
    transition(name="fade" mode="out-in")
      div(v-if="classroomList.length > 0" key="classroomList")
        .reg-classroom
          transition-group(name="fade")
            div(v-for="classroomData in classroomList" :key="classroomData.class_applications")
              vip-classroom(:show_consultant_img='true' :classroom_type="'normal'" :classroomData="classroomData")
        vip-week-class-count
        b-pagination(v-model="currentPage" :total-rows="classroomPaination.last_page" :per-page="1" align="center")
      div(v-else key="empty")
        .reg-no-booking
          span.mt-3(v-html="$t('classroom.no_class_message')")
</template>

<script type="text/javascript">
import classroomConstant from '@/constants/classroom';
import ServerTimeClock from '@/components/serverTimeClock';
import PhoneHelp from '@/components/common/phoneHelp';
import VipClassroom from '@/components/class/vipClassroom';
import VipWeekClassCount from '@/components/class/vipWeekClassCount';
import LioshutanApi from '@/api';

export default {
  name: 'EnterClassroom',

  components: {
    ServerTimeClock,
    PhoneHelp,
    VipClassroom,
    VipWeekClassCount,
  },

  data() {
    return {
      currentPage: 1, // 第1頁起始

      classroomList: {},

      classroomPaination: {},

      cloudClassRoomTimer: '',
    };
  },

  watch: {
    currentPage(next) {
      if (next !== this.classroomPaination.current_page) {
        this.getCurrentClass();
      }
    },
  },

  created() {
    this.getCurrentClass();
    this.reCallCloudClassroom();
  },

  beforeDestroy() {
    clearTimeout(this.cloudClassRoomTimer);
  },

  methods: {
    async getCurrentClass() {
      // 只顯示當日可上課教室 cts: 當日 00:00:00, cte: 當日 23:59:59
      const params = {
        searchType: classroomConstant.FILTER_STATUS.CLOUD_CLASSROOM,
        page: this.currentPage,
      };
      // 目前直接使用api資料夾回傳 response.data 資料
      const res = await LioshutanApi.classroom.getClassroom(params);
      this.classroomList = res.data;
      this.classroomPaination = res.meta.pagination;
    },

    reCallCloudClassroom(){
      this.cloudClassRoomTimer = setTimeout(() => {
        this.getCurrentClass();
        this.reCallCloudClassroom();
      }, classroomConstant.CLOUD_TABLE.REFRESH_TIME);
    },
  },
};
</script>

<style lang="scss">
@import '@/styles/class/classroom';
@import '@/styles/transition';
</style>

